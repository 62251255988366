import React, {useRef} from 'react'
import {styled} from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../transition.scss'

const PREFIX = 'Wrapper'

const classes = {
  root: `${PREFIX}-root`
}

const Root = styled('div')((
  {
    theme
  }
) => ({
  padding: `${theme.spacing(2)}`,
  height: 'calc(90vh - 65px)',
  maxHeight: 'calc(750px - 65px)',
  overflowY:'auto'
}))

function Wrapper (props) {
  // eslint-disable-next-line
  const {children, className, useTransitionGroup = false, transitionKey} = props

  const nodeRef = useRef(null)

  return (
    <Root ref = {nodeRef} className={classNames(classes.root, classes.test, className)}>
      {children}
    </Root>
  )
}

Wrapper.propTypes = {
  useTransitionGroup: PropTypes.bool,
  transitionKey: PropTypes.string
}

export default (Wrapper)
