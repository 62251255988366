import {IGCError} from '../handlers/errorHandler'
const axios = require('axios').default;

export default class SowApiDataLayer {
  constructor (apiUrl, apiKey, authenticationToken) {
    this.sowApiRequest = axios.create({
      headers: {'ApiKey': apiKey, 'AuthenticationToken': authenticationToken},
      baseURL: apiUrl,
      responseType: 'json'
    })
  }

  static init (apiUrl, apiKey, authenticationToken) {
    return new SowApiDataLayer(apiUrl, apiKey, authenticationToken)
  }

  get Kyc () {
    return {
      getAMLVerificationStatus: async () => {
        return this.sowApiRequest.get('/v1/igc/Kyc/GetAmlVerificationStatus').then(SowApiDataLayer.handleIgcResponse)
      },
      postSowQuestionnaire: async (data) => {
        return this.sowApiRequest.post('/v1/igc/Kyc/PostSowQuestionnaire', {...data})
          .then(SowApiDataLayer.handleIgcResponse)
      },
      getSowQuestionnaire: async (data) => {
        return this.sowApiRequest.get('/v1/igc/Kyc/GetSowQuestionnaire').then(SowApiDataLayer.handleIgcResponse)
      }
    }
  }

  get User () {
    return {
      read: async () => {
        return this.sowApiRequest.post('/v1/igc/User').then(SowApiDataLayer.handleIgcResponse)
      },
      getKycApprovalStatuses: async () => {
        return this.sowApiRequest.get('/v1/igc/User/GetKycApprovalStatuses').then(SowApiDataLayer.handleIgcResponse)
      },
      getKycStatus: async () => {
        return this.sowApiRequest.get('/v1/igc/User/GetKycStatus').then(SowApiDataLayer.handleIgcResponse)
      },
      kyc: async () => {
        return this.sowApiRequest.post('/v1/igc/User/Kyc').then(SowApiDataLayer.handleIgcResponse)
      },
      kycUpload: async (data) => {
        return this.sowApiRequest.post('/v1/igc/User/Kyc/Upload', {...data})
          .then(SowApiDataLayer.handleIgcResponse)
      },
      kycUploadWithIdDocInfo: async (data) => {
        return this.sowApiRequest.post('/v1/igc/User/Kyc/UploadWithIdDocInfo', {...data})
          .then(SowApiDataLayer.handleIgcResponse)
      },
      gracePeriod: async () => {
        return this.sowApiRequest.post('/v1/igc/User/Kyc/GracePeriod').then(SowApiDataLayer.handleIgcResponse)
      },
      getLoginHistory: async () => {
        return this.sowApiRequest.get('/v1/igc/User/GetLoginHistory').then(SowApiDataLayer.handleIgcResponse)
      },
      addUserNote: async (note) => {
        return this.sowApiRequest.post('/v1/igc/User/AddUserNote', {note})
          .then(SowApiDataLayer.handleIgcResponse)
      }
    }
  }

  get Tags () {
    return {
      getUserTags: async () => {
        return this.sowApiRequest.get('/v1/igc/Tags/GetUserTags').then(SowApiDataLayer.handleIgcResponse)
      },
      unassignUserTag: async (tagId) => {
        return this.sowApiRequest.post('/v1/igc/Tags/UnassignUserTag', {tagId})
          .then(SowApiDataLayer.handleIgcResponse)
      }
    }
  }

  get Payments () {
    return {
      getUserDepositLimits: async () => {
        return this.sowApiRequest.get('/v1/igc/Payments/GetUserDepositLimits')
          .then(SowApiDataLayer.handleIgcResponse)
      }
    }
  }

  get Countries () {
    return {
      getCountry: async (data) => {
        return this.sowApiRequest.post('/v1/igc/GetCountry', data)
          .then(SowApiDataLayer.handleIgcResponse)
      }
    }
  }

  static handleIgcResponse (response) {
    let {Success, Data, Errors} = response.data
    if (Success) {
      return Data
    } else {
      throw new IGCError(JSON.stringify(Errors))
    }
  }
}
