import React, {Component} from 'react'
import {styled} from '@mui/material/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import Button from '../../Common/components/Button'

import {
  handleChange,
  getUploadStatusText,
  handleDelete
} from './actions'
import {getLocalTheme} from '../../../helpers/storageHelper'

import i18n from 'i18n-js';

const PREFIX = 'FileField'

const classes = {
  root: `${PREFIX}-root`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  btnLarge: `${PREFIX}-btnLarge`,
  files: `${PREFIX}-files`,
  text: `${PREFIX}-text`,
  chip: `${PREFIX}-chip`,
  brand: `${PREFIX}-brand`,
  brandDisabled: `${PREFIX}-brandDisabled`,
  brandChip: `${PREFIX}-brandChip`
}

const Root = styled('div')((
  {
    theme
  }
) => ({

  margin: `${theme.spacing(1)} 0`,

  [`& .${classes.input}`]: {
    display: 'none'
  },

  [`& .${classes.button}`]: {
    width: '100%'
  },

  [`& .${classes.btnLarge}`]: {
    paddingTop: `${theme.spacing(2)}`,
    paddingBottom: `${theme.spacing(2)}`
  },

  [`& .${classes.files}`]: {
    marginTop: `${theme.spacing(1)}`
  },

  [`& .${classes.text}`]: {
    paddingLeft: 12,
    paddingRight: 12
  },

  [`& .${classes.chip}`]: {
    marginRight: `${theme.spacing(1)}`,
    marginBottom: `${theme.spacing(1)}`
  },

  [`& .${classes.brand}`]: brandTheme.fileUpload,

  [`& .${classes.brandDisabled}`]: {
    ...brandTheme.fileUploadDisabled,
    cursor: 'not-allowed',
    '&:hover': {
      background: 'none'
    }
  },

  [`& .${classes.brandChip}`]: brandTheme.file
}))

const brandTheme = getLocalTheme()

class FileField extends Component {
  constructor (props) {
    super(props)

    this.handleChange = handleChange.bind(this)
    this.handleDelete = handleDelete.bind(this)
    this.getUploadStatusText = getUploadStatusText.bind(this)
    this.state = {
      files: []
    }
  }

  render () {
    const {
      name,
      large,
      documentData,
      addFileToQueue,
      removeFileFromQueue,
      files,
      documentData: {limit},
      typeId,
      typeTitle,
      ...other
    } = this.props

    let uploadStatus = (files) ? files.size : 0

    const className = classNames(
      classes.button,
      {[classes.btnLarge]: large},
      classes.brand,
      {[classes.brandDisabled]: (uploadStatus === limit)}
    )
    const colorProps = (!brandTheme.fileUpload) ? {color: 'primary'} : {}

    return (
      <Root className={classes.root}>
        <input
          className={classes.input}
          id={name}
          name={name}
          multiple={true}
          type='file'
          onChange={this.handleChange}
          disabled={uploadStatus === limit}
        />
        <label htmlFor={name}>
          <Button
            {...colorProps}
            {...other}
            className={className}
          >
            <Typography {...colorProps} className={classes.text}>
              {/* <CloudUpload /> */}
              {i18n.t(`documents.cta.upload`, {limit: limit, type: typeTitle})}
            </Typography>
          </Button>
        </label>
        <div className={classes.files}>
          {files !== undefined && Array.from(files).map(([key, value]) => {
            const {blob: {name}} = value
            let label = name

            if (value.blob.name.length > 25) {
              const filename = name.substr(0, 25)
              const filetypeStart = name.lastIndexOf('.')
              const filetype = name.substr(filetypeStart, name.length - 1)
              label = `${filename}...${filetype}`
            }

            return <Chip
              className={classNames(classes.chip, classes.brandChip)}
              key={key}
              label={label}
              color='primary'
              variant='outlined'
              onDelete={() => this.handleDelete(key)}
            />
          })}
        </div>
      </Root>
    )
  }
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  large: PropTypes.bool
}

export default (FileField)
