import React, {Component} from 'react'
import {styled} from '@mui/material/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FormGroup from '@mui/material/FormGroup'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

import {ExpandMore, ExpandLess} from '@mui/icons-material'
import {getLocalTheme} from '../../../helpers/storageHelper'

import i18n from 'i18n-js';

const PREFIX = 'MultipleOptionsField'

const classes = {
  root: `${PREFIX}-root`,
  listItem: `${PREFIX}-listItem`,
}

const StyledList = styled(List)((
  {
    theme
  }
) => ({
  ...brandTheme.select,
  padding: 0,

  listItem: {
    border: `1px solid ${theme.palette.action.disabled}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
  },

}))

const brandTheme = getLocalTheme()

class MultipleOptionsField extends Component {
  constructor (props) {
    super(props)

    this.state = {
      collapse: false,
      showOptions: 'no',
      values: []
    }

    this.handleCollapse = this.handleCollapse.bind(this)
    this.handleShowOptions = this.handleShowOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    const {options: {Id}, handleFieldSubmit, preSelectData} = this.props

    if (preSelectData) {
      this.setState({
        value: preSelectData
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: [preSelectData]
      })
    }
  }

  handleCollapse () {
    this.setState({
      collapse: !this.state.collapse
    })
  }

  handleShowOptions (event) {
    const { handleUserRequiredIDs, options } = this.props

    const showOptions = event.target.value === 'yes'

    this.setState({
      collapse: false,
      showOptions: event.target.value,
      values: (showOptions) ? this.state.values : []
    })

    const type = (showOptions) ? 'mandatory' : 'optional'
    handleUserRequiredIDs(type, options.Id)
  }

  handleChange (event) {
    let {values} = this.state
    const {options: {Id}, handleFieldSubmit} = this.props

    if (event.target.checked) {
      values.push(event.target.value)
    } else {
      let index = values.indexOf(event.target.value)
      values.splice(index, 1)
    }

    this.setState({values}, () => {
      let {values} = this.state

      values = values.map((value) => {
        return parseInt(value)
      })

      handleFieldSubmit({
        SowQuestionId: Id,
        SowAnswerIds: values
      })
    })
  }

  render () {
    const {id, fieldId, options: {SowAnswers}, hide, preOptionsText} = this.props
    const {collapse, values} = this.state

    const question = `questions.${id}`
    let icon = (collapse) ? <ExpandLess /> : <ExpandMore />

    return (
      <StyledList className={classNames(classes.root, {'hide': hide})}>
        <ListItem
          button
          onClick={this.handleCollapse}
          className={classNames(classes.listItem, {[classes.brand]: !!brandTheme.select})}
        >
          <ListItemText primary={i18n.t(`${question}.text`)} />
          {icon}
        </ListItem>
        <Collapse in={collapse} timeout='auto'>
          {preOptionsText && <Typography>{preOptionsText}</Typography>}
          <FormGroup>
            {SowAnswers.map(({Id}) => {
              const label = i18n.t(`${question}.answers.${Id}`)

              return (
                <FormControlLabel
                  key={Id}
                  control={
                    <Checkbox
                      id={`${fieldId}_${label.replace(/\s+/g, '-').toLowerCase()}`}
                      checked={values.includes(Id.toString())}
                      onChange={this.handleChange}
                      value={Id.toString()}
                    />
                  }
                  label={label}
                />
              )
            })}
          </FormGroup>
        </Collapse>
      </StyledList>
    )
  }
}

MultipleOptionsField.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number.isRequired,
  fieldId: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  hide: PropTypes.bool,
  preOptionsText: PropTypes.string
}

export default (MultipleOptionsField)
